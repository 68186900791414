<template>
  <div class="row">
    <div class="col-lg-4 col-md-6 col-12 item">
      <!-- Begin services item -->
      <router-link to="#" class="services-item item-style">
        <div class="services-item-ico">
          <i class="material-icons material-icons-outlined md-48">flight</i>
        </div>
        <h4 class="services-item-title">Travel Industry</h4>
        <div
          class="services-item-desc"
        >As a business we are committed to providing data and software solutions to the Travel Industry, we have been working in this sector now for over 15 years. Processing millions of flight and holiday offers we have complete market snapshot of the UK travel industry.</div>
      </router-link>
      <!-- End services item -->
    </div>

    <div class="col-lg-4 col-md-6 col-12 item">
      <!-- Begin services item -->
      <router-link to="#" class="services-item item-style">
        <div class="services-item-ico">
          <i class="material-icons material-icons-outlined md-48">developer_mode</i>
        </div>
        <h4 class="services-item-title">Systems Development</h4>
        <div class="services-item-desc">
          <p>Hosting websites, databases and source code repositories in the cloud has been at the heart of our business for the past 10 years.</p>
          <p>From a technological standpoint we have evolved in partnership with Microsoft Windows computing at the core of our on-line presence.</p>
        </div>
      </router-link>
      <!-- End services item -->
    </div>

    <div class="col-lg-4 col-md-6 col-12 item">
      <!-- Begin services item -->
      <router-link to="#" class="services-item item-style">
        <div class="services-item-ico">
          <i class="material-icons material-icons-outlined md-48">web</i>
        </div>
        <h4 class="services-item-title">Website Design and Development</h4>
        <div class="services-item-desc">
          <p>At the cornerstone of our web strategy is Umbraco, the premier CMS platform for .NET. From our roots we evolved starting with classic ASP.NET, moving swiftly on to Javascript and jQuery technologies. Umbraco gives us a great starting point from which to project our ideas and not only that our customers love it!</p>

          <p>
            <span>We are now working with the latest layout frameworks like Twitter Bootstrap and AngularJS.</span>
          </p>
        </div>
      </router-link>
      <!-- End services item -->
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style>
</style>